<template>

	<div class="invoice-records">

		<div class="title-content">
			<div class="title">开票记录</div>
			<div class="line"></div>
		</div>

		<div class="record-content">

			<div class="invoice-search">

				<el-row>
					<el-select size="medium" v-model="invoice_status" placeholder="请选择">
						<el-option v-for="item in invoice_status_options" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option> 
					</el-select>

					<el-button size="medium" type="info" @click="getList()" icon="el-icon-search"> 搜索 </el-button>
					<el-button size="medium" type="success" @click="openCreateForm()"
						icon="el-icon-circle-plus-outline">申请开票</el-button>

				</el-row>

			</div>
			<el-divider></el-divider>
			<div class="invoice-content" v-if="total>0">
				<div class="invoice-items">
					<div class="items" v-for="(item,index) in invoiceList" :key="index">
						<div class="invoice-status flex-items">

							<span>申请时间：{{ item.created_at }}</span>

							<span v-if="item.invoice_status > 0">审核时间：{{ item.verify_at }}</span>
						</div>
						<div class="invoice flex-items">

							<div class="infos">
								<div class="title">{{item.company_name}}</div>
								<div class="title">{{item.company_code}}</div>
								<div class="date flex-items">
									<div>发票备注：</div>

									<div>{{item.invoice_remarks}}</div>
								</div>
								<div class="date flex-items">
									<div>收件邮箱：</div>

									<div>{{item.email}}</div>
								</div>
							</div>
							<div class="status">
								<div class="invoice_amount">￥{{item.invoice_amount}}</div>
								<el-button class="btn" type="warning" v-if="item.invoice_status==0">待审核</el-button>
								<el-button class="btn" type="success"
									v-else-if="item.invoice_status==1">审核通过</el-button>
								<el-button class="btn" type="danger" v-else-if="item.invoice_status==2">审核驳回</el-button>
							</div>
						</div>
					</div>
				</div>
				<div class="page-view">
					<el-pagination :current-page="page" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" background
						layout="total,sizes,prev, pager, next" :total="total" @size-change="handleSizeChange"
						@current-change="handleCurrentChange">
					</el-pagination>
				</div>

			</div>
			<div class="empty" v-if="hasData&&invoiceList.length==0">
				<img src="../../../assets/images/empty.png" alt="">
			</div>
		</div>

		<el-dialog :title="'开票申请 可用余额：' + balance + '元' " :visible.sync="createFormDisable" width="35%">
			<!-- 表单元素 -->
			<el-form ref="createForm" :model="createForm" label-width="80px">
				<el-form-item label="企业名称">
					<el-input size="medium" v-model="createForm.company_name" :maxlength="30"></el-input>
				</el-form-item>
				<el-form-item label="统一社会信用代码">
					<el-input size="medium" v-model="createForm.company_code" :maxlength="20"></el-input>
				</el-form-item>
				<el-form-item label="开票金额">
					<el-input-number size="medium" v-model="createForm.invoice_amount" :precision="2" :min="10"
						:step="10" :max="balance"></el-input-number>
				</el-form-item>
				<el-form-item label="发票上备注">
					<el-input size="medium" type="textarea" :maxlength="30" :rows="2"
						v-model="createForm.invoice_remarks"></el-input>
				</el-form-item>
				<el-form-item label="邮箱">
					<el-input size="medium" :maxlength="100" v-model="createForm.email"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="createSubmitForm()">提交</el-button>
					<el-button @click="createResetForm()">重置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>


	</div>
</template>

<script>
	import {
		Message
	} from 'element-ui';
	export default {
		name: "invoiceRecords",
		data() {
			return {
				createFormDisable: false,
				invoiceList: [],
				total: 0,
				pageSize: 10,
				page: 1,
				hasData: false,
				balance: 2000.00,
				invoice_status: null,
				invoice_status_options: [{
					value: null,
					label: '请选择'
				}, {
					value: 0,
					label: '等待审核'
				}, {
					value: 1,
					label: '审核通过'
				}, {
					value: 2,
					label: '审核驳回'
				}],

				createForm: {
					company_name: '',
					company_code: '',
					invoice_amount: '',
					invoice_remarks: '',
					email: '',
				}

			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				this.$get('/api/invoice', {
					page: this.page,
					limit: this.pageSize,
					invoice_status: this.invoice_status,
				}).then(res => {
					this.total = res.data.total;
					this.invoiceList = res.data.list;
					this.hasData = true
				}).catch(() => {
					this.hasData = false
				})
			},
			handleSizeChange(val) {
				this.pageSize = val
				this.getList()
			},
			handleCurrentChange(val) {
				this.page = val
				this.getList()
			},
			closeCreateForm() {
				this.createFormDisable = false;
				this.createResetForm();
			},
			openCreateForm() {

				this.$get('/api/user/invoice/account', {

				}).then(res => {
					this.balance = parseFloat(res.data.balance);
					this.createResetForm();
					this.createFormDisable = true;
				})

			},
			createResetForm() {
				this.createForm.company_name = '';
				this.createForm.company_code = '';
				this.createForm.invoice_amount = '';
				this.createForm.invoice_remarks = '';
				this.createForm.email = '';
			},
			createSubmitForm() {

				this.$put('/api/invoice', this.createForm).then(res => {

					if (res.code == 200) {
						Message.success(res.msg);
						this.closeCreateForm();
						this.getList();
					}

				})
			},

		}
	}
</script>

<style scoped lang="scss">
	.invoice-records {

		.invoice-search {

			.el-row {
				line-height: 60px;
				height: 60px;


				.el-select {
					margin-left: 20px;
				}

				.el-button {
					margin-left: 25px;
					margin-right: 25px;
				}

			}
		}

		.invoice-content {
			background: #FFFFFF;
			border-radius: 10px;
			padding: 42px 23px 10px;



			.invoice-items {
				.items {
					box-shadow: 0px 4px 10px 0px rgba(255, 5, 55, 0.149);
					border: 1px solid #E5E5E5;
					margin-bottom: 21px;
				}

				.invoice-status {
					height: 34px;
					background: #F6F6F6;
					padding-left: 17px;
					opacity: 1;
					border: 1px solid #F6F6F6;
					font-size: 14px;
					font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
					font-weight: 400;
					color: #9E9E9E;

					span:last-child {
						margin-left: 50px;
					}
				}

				.invoice {
					padding: 20px;

					.name {
						width: 138px;
						height: 138px;
						background: #E93A13;
						border-radius: 10px 10px 10px 10px;
						text-align: center;
						font-size: 24px;
						font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
						font-weight: 700;
						color: #FFFFFF;
					}

					.infos {
						margin: 0 22px;
						flex: 1;

						.title {
							font-size: 20px;
							font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
							font-weight: 500;
						}

						.intro {
							margin-top: 16px;
							font-size: 14px;
							font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
							font-weight: 500;
							color: #9E9E9E;
						}

						.date {
							font-size: 14px;
							font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
							font-weight: 500;
							color: #9E9E9E;
							margin-top: 30px;

							.line {
								width: 2px;
								height: 15px;
								background-color: #9E9E9E;
								margin: 0 10px;
							}
						}
					}

					.status {
						display: flex;
						flex-direction: column;
						align-items: center;

						.invoice_amount {
							font-size: 24px;
							font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
							font-weight: 500;
							color: #F42323;
						}

						.btn {
							width: 128px;
							line-height: 46px;
							text-align: center;
							border-radius: 50px;
							margin-top: 17px;
							color: #fff;
							font-size: 18px;
							font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
							font-weight: 400;
							padding: 0;
						}
					}
				}
			}

			.page-view {
				text-align: right;
			}
		}
	}
</style>
