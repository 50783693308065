/* eslint-disable */
/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from "axios";
import router from "../router";
import store from "../store/index";
import {
	Message,
	Loading
} from 'element-ui';
import qs from "qs";


/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = msg => {
	Message.error(msg)
}
/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
	let route = router.history.current.path
	if (route != '/') {
		router.push({
			path: "/"
		})
	}
	// router.push({ path: "/login" });
}
const toAddPath = () => {
	router.push({
		path: "/addPath"
	});
}
/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
	// 状态码判断
	switch (status) {
		// 400: 用户名或密码错误
		case '400':
			tip(other)
			break;
			// 401: 未登录状态，跳转登录页
		case 401:
			// toLogin();
			// tip("登录过期，请重新登录");

			localStorage.removeItem("token");
			store.commit("loginSuccess", null);
			toLogin();
			// setTimeout(() => {
			//   toLogin();
			// }, 1000);
			break;
			// 403 token过期
			// 清除token并跳转登录页
		case 406:
			// tip("登录过期，请重新登录");
			store.commit("setToken", '');
			toLogin()
			break;
			// 404请求不存在
		case 404:
			tip("请求的资源不存在");
			break;
			// 429:
		case 429:
			tip("请求频繁,稍后再试")
			break;
		default:
			tip(other);
	}
}
const closeLoading = () => {
	loadingInstance.close();
}
// 创建axios实例
var instance = axios.create({
	timeout: 1000 * 12
});
let loadingInstance = '';
// 设置post请求头
// instance.defaults.headers.post["Content-Type"] = "multipart/form-data";
// instance.defaults.withCredentials = true;
instance.defaults.baseURL = "https://api.jxmzjzfw.com";
//instance.defaults.baseURL = "http://jxmz.local.com";

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
	config => {
		// 登录流程控制中，根据本地是否存在token判断用户的登录情况
		// 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
		// 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
		// 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作
		loadingInstance = Loading.service({
			text: '正在拼命加载'
		});
		const token = store.state.token;
		token && (config.headers.Authorization = "Bearer " + token);
		// if (config.method === "post" && config.data.constructor !== FormData) {
		//   config.data = qs.stringify(config.data);
		// }
		if (config.method === "post" && config.data && config.data.constructor !== FormData) {
			config.data = qs.stringify(config.data);
		}
		return config;
	},
	error => Promise.error(error)
)
// 响应拦截器
instance.interceptors.response.use(
	res => {
		closeLoading()
		if (res.status === 200) {
			return Promise.resolve(res)
		} else {
			return Promise.reject(res)
		}
	},
	error => {
		const {
			response
		} = error;

		closeLoading()

		if (response) {
			// 请求已发出，但是不在2xx的范围
			errorHandle(response.status, response.data.msg);

			return Promise.reject(response);
		} else {
			// 处理断网的情况
			// eg:请求超时或断网时，更新state的network状态
			// network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
			// 关于断网组件中的刷新重新获取数据，会在断网组件中说明
			if (!window.navigator.onLine) {
				//   store.commit("changeNetwork", false);
			} else {
				return Promise.reject(error);
			}
		}
	});

const http = {
	get: (url, param) => {
		return new Promise((resolve, reject) => {
			instance.get(url, {
				params: param
			}).then((res) => {
				if (res.data.code === 200) {
					resolve(res.data);
				} else {

					reject(res.data);
					// errorHandle(res.data.code, res.data.msg);
				}
			}).catch((err) => {

				// Message.error(err.data.msg);
				reject(err);
			})
		})
	},
	post: (url, param) => {
		return new Promise((resolve, reject) => {
			instance.post(url, param).then((res) => {
				if (res.data.code === 200) {
					resolve(res.data);
				} else {
					reject(res.data);
					errorHandle(res.data.code, res.data.msg);
				}

			}).catch((err) => {
				// console.log(err)
				// Message('123123');

				reject(err);
			})
		})
	},
	put: (url, param) => {
		return new Promise((resolve, reject) => {
			instance.put(url, qs.stringify(param)).then((res) => {

				if (res.data.code === 200) {
					resolve(res.data);
				} else {
					reject(res.data);
					errorHandle(res.data.code, res.data.msg);
				}

			}).catch((err) => {
				// console.log(err)
				// Message('123123');

				reject(err);
			})
		})
	}
}

export {
	instance,
	http
};
